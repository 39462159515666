import ErrorView from "components/ui/ErrorView/ErrorView";
import React from "react";
import CustomerTickets from "./CustomerTickets/CustomerTickets";
import CustomerTicketsSkeleton from "./CustomerTicketsSkeleton/CustomerTicketsSkeleton";
import { dataQueryStatus } from "utils";
import {
    ASSIGNED,
    AUTOMATED,
    NO_TAGS,
    NO_TICKET_INFO,
    ticketPhases,
    UNDISCOVERED,
} from "components/dashboard/AgentInbox/enum";
import { useSelector } from "react-redux";
import CTCHeading from "./CTCHeading/CTCHeading";
import "./CustomerTicketsContainer.scss";

const { LOADING, DATAMODE, ERROR } = dataQueryStatus;
const { ISSUE_DISCOVERY, PROBLEM_CONFIRMATION, SOLUTION_DELIVERY } =
    ticketPhases;

const CustomerTicketsContainer = ({
    status,
    errorMssg,
    handleRetry,
    handleRemoveEmailPreview,
    selectedTicketHeader,
    clearSelectedCustomerMessage,
}) => {
    const { userId } = useSelector((state) => state.auth?.user);

    const {
        ticketPhase: currentFilterTicketPhase,
        messageTagId: filterMessageTagId,
        ticketInfoValue,
        classification: filterClassification,
        isMentioned,
        followUpActive,
        ticketSource,
        search,
        agentId,
    } = useSelector((state) => state?.ticketsFilters.ticketsfilterRequest);
    const { incomingTickets } = useSelector((state) => state?.incomingTickets);

    const filterByTicketPhase = (ticket) => {
        if (!currentFilterTicketPhase) {
            return true;
        }

        if (
            (currentFilterTicketPhase === SOLUTION_DELIVERY ||
                currentFilterTicketPhase === PROBLEM_CONFIRMATION) &&
            ticket?.followUpActive
        ) {
            return false;
        }

        return ticket.ticketPhase === currentFilterTicketPhase;
    };

    const filterByMessageTag = (ticket) => {
        if (currentFilterTicketPhase !== ISSUE_DISCOVERY) {
            return true;
        }

        if (!filterMessageTagId) {
            return true;
            // return isMentioned || followUpActive
            //     ? true
            //     : ticket.issueDiscovered;
        }

        if (filterMessageTagId === UNDISCOVERED) {
            return !ticket.issueDiscovered;
        }

        if (filterMessageTagId === NO_TAGS) {
            return (
                (ticket.messageTags === null || ticket.messageTags === "") &&
                ticket.issueDiscovered === true
            );
        }

        return (
            ticket.issueDiscovered &&
            ticket.messageTags?.split(",")?.includes(filterMessageTagId)
        );
    };

    const filterByTicketInfoValue = (ticket) => {
        let isAssigned;

        if (ticket?.assigned) {
            if (agentId) {
                isAssigned = ticket?.assignedTeamMemberId === agentId;
            } else {
                isAssigned = ticket?.assignedTeamMemberId === userId;
            }
        }

        let infoIndex = ticket?.info?.findIndex(
            (el) => el.ticketInfoValue === ticketInfoValue
        );

        if (ticket.ticketPhase === ISSUE_DISCOVERY) {
            return true;
        }

        if (!ticketInfoValue) {
            return isMentioned || followUpActive ? true : isAssigned;
        }

        if (ticketInfoValue === AUTOMATED) {
            return !ticket?.assigned;
        }

        if (ticketInfoValue === ASSIGNED) {
            return ticket?.assigned || ticket?.issueDiscovered === true;
        }

        if (ticketInfoValue === NO_TICKET_INFO) {
            return (
                // isAssigned &&
                (ticket?.info === null || ticket?.info?.length < 1)
            );
        }

        const belongsToAgent = isMentioned ? true : isAssigned;
        return belongsToAgent && infoIndex !== -1;
    };

    const filterByClassification = (ticket) => {
        if (ticket.ticketPhase === ISSUE_DISCOVERY) {
            return true;
        }

        if (!filterClassification) {
            return true;
        }

        return ticket.ticketClassification === filterClassification;
    };

    const filterByMentions = (ticket) => {
    
        const actingId = agentId || userId;

        if (isMentioned !== true) {
            return true;
        }

        if (
            ticket?.commentResolved?.[0] &&
            ticket?.commentResolved?.[1] === actingId
        ) {
            return false;
        }

        let userIdList = [];
        if (ticket?.comments) {
            const mentioned = ticket?.comments?.map(
                (comment) => comment?.mentioned
            );

            const allMentioned = mentioned?.flat();
            userIdList = allMentioned?.map((mention) => mention?.userId);
        }
  

        return ticket?.comments
            ? userIdList?.includes(actingId)
            : ticket?.mentioned?.includes(actingId);
    };

    const filterByTicketStatus = (ticket) => {
        return ticket.ticketStatus;
    };

    const filterByTicketSource = (ticket) => {
        if (!ticketSource) {
            return true;
        }

        return ticket?.ticketSource === ticketSource;
    };

    const filterBySearch = (ticket) => {
        if (!search) {
            return true;
        }

        const {
            customer: { email, appMessageUserId },
        } = ticket || {};

        return (
            email?.toLowerCase()?.includes(search?.toLowerCase()) ||
            appMessageUserId?.toLowerCase()?.includes(search?.toLowerCase())
        );
    };

    const filter = () => {
        return incomingTickets
            ?.filter(filterByTicketPhase)
            .filter(filterByMessageTag)
            .filter(filterByMentions)
            .filter(filterByTicketInfoValue)
            .filter(filterByClassification)
            .filter(filterByTicketSource)
            .filter(filterByTicketStatus)
            .filter(filterBySearch);
    };

    const filterSearch = () => {
        return incomingTickets
            ?.filter(filterByTicketPhase)
            .filter(filterByMentions)
            .filter(
                currentFilterTicketPhase === ISSUE_DISCOVERY
                    ? filterByTicketInfoValue
                    : filterByMessageTag
            )
            .filter(filterByClassification)
            .filter(filterByTicketSource)
            .filter(filterByTicketStatus)
            .filter(filterBySearch);
    };

    // currentFilterTicketPhase
    const ticketsByFilters =
        !currentFilterTicketPhase && !isMentioned && !followUpActive
            ? search?.length > 0
                ? filterSearch()
                : []
            : (currentFilterTicketPhase || isMentioned || followUpActive) &&
              !ticketInfoValue &&
              !filterMessageTagId
            ? search?.length > 0
                ? filterSearch()
                : []
            : filter();

    const renderBasedOnStatus = () => {
        switch (status) {
            case LOADING:
                return <CustomerTicketsSkeleton />;

            case DATAMODE:
                return (
                    <CustomerTickets
                        data={ticketsByFilters}
                        handleRemoveEmailPreview={handleRemoveEmailPreview}
                        selectedTicketHeader={selectedTicketHeader}
                        clearSelectedCustomerMessage={clearSelectedCustomerMessage}
                    />
                );

            case ERROR:
                return (
                    <ErrorView message={errorMssg} handleRetry={handleRetry} />
                );

            default:
                return "";
        }
    };

    return (
        <div className='relative__layout h-100'>
            <CTCHeading data={ticketsByFilters} />
            <div
                // custom-scroll-bar
                className={`customer__tickets--container custom-scroll-bar`}
                id='allTickets'>
                <div className='tickets__container '>
                    {renderBasedOnStatus()}
                </div>
            </div>
        </div>
    );
};

export default CustomerTicketsContainer;
