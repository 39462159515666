import React, { useContext, useState } from "react";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import { Modal, Button } from "components/ui";
import { apiRoute } from "services";
import API from "services/lib/api";
import { dataQueryStatus } from "utils";
import { getErrorMessage } from "utils/helper";
import ScheduleInput from "components/dashboard/common/ScheduleInput/ScheduleInput";

const { ERROR } = dataQueryStatus;

const SnoozeTicket = ({ show, closeModal, handleSuccess, referenceTicket }) => {
    const [isLoading, setLoading] = useState(false);
    const [snoozeTill, setSnoozeTill] = useState(0);
    const toastMessage = useContext(ToastContext);

    const { ticketId } = referenceTicket || {};

    const handleSnoozeSuccess = (message = "Ticket snoozed") => {
        toastMessage(message);
        handleSuccess();
        closeModal();
    };


    const handleSnoozeTicket = async () => {
        try {
            
            setLoading(true);

            const url = apiRoute?.snoozeTicket(ticketId);
            const res = await API.put(url, {
                snoozedTill: snoozeTill,
            });

            if (res.status === 200) {
                const { message } = res.data;
                handleSnoozeSuccess(message);
                setLoading(false);
            }
        } catch (err) {
            setLoading(false);
            const message = getErrorMessage(err);
            toastMessage(message, ERROR);
        }
    };

    return (
        <Modal show={show} closeModal={closeModal}>
            <div className='confirm-action p-5'>
                <h1 className='text-center fw-medium'>{`Snooze ${referenceTicket?.customer?.email} ticket?`}</h1>
                <ScheduleInput
                    label="Snooze till"
                    subLabel="Select how long you would like to snooze this ticket for"
                    timeValue={snoozeTill}
                    handleTimeChange={setSnoozeTill}
                />
                <div className="snooze__ticket__modal__footer">
                    <Button
                        type='button'
                        text={"Continue"}
                        classType='danger'
                        otherClass='mt-3 mb-2 w-100 text-white continue-button'
                        onClick={handleSnoozeTicket}
                        disabled={isLoading || snoozeTill === 0}
                        loading={isLoading}
                    />
                    <Button
                        type='button'
                        text='Cancel'
                        classType='muted'
                        otherClass='my-2 w-100 close-button'
                        onClick={closeModal}
                        disabled={isLoading}
                    />
                </div>
            </div>

        </Modal>
    );
};

export default SnoozeTicket;
