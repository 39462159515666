import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactSVG } from "react-svg";
import imageLinks from "assets/images";
import { updateTicketsFilters } from "store/ticketsFilters/actions";
import "./CTCHeading.scss";

const CTCHeading = ({ data }) => {
    const dispatch = useDispatch();
    const { lastModified } = useSelector(
        (state) => state.ticketsFilters.ticketsfilterRequest
    );
    const newTicketsCount = data?.filter((x) => x.isNew === true)?.length;

    const handleLastModifiedSort = () => {
        dispatch(
            updateTicketsFilters({
                filterName: "lastModified",
                filterValue: lastModified === "desc" ? "asc" : "desc",
            })
        );

        // dispatch(
        //     updateTicketsFilters({
        //         filterName: "page",
        //         filterValue: 1,
        //     })
        // );
    };

    return (
        <div className='tside__heading'>
            <div className="tside__heading__container">
                <h6>Assigned tickets</h6>
                {data?.length > 0 && <div className={`sort__trigger ${lastModified === "desc" ? "sort__trigger--desc" : "sort__trigger--asc"}`}
                    onClick={handleLastModifiedSort}
                >
                    <ReactSVG
                        src={imageLinks?.icons?.funnelFilter}
                    />
                </div>}
            </div>
            {newTicketsCount > 0 && (
                <div className='countable__badge'>New Tickets Arrived</div>
            )}
        </div>
    );
};

export default CTCHeading;
