import ShinyLoader from 'components/ui/ShinyLoader/ShinyLoader';
import React from 'react';

export const CustomerTicketsSkeletonElement = () => (
    <div className='customer__ticket '>
        <div className='email__time row align-items-center'>
            <div className='col-10'>
                <ShinyLoader width={'100%'} height="16px" />
            </div>
            <div className='col-2'>
                <ShinyLoader width={'100%'} height="16px" />
            </div>
        </div>
        <div className="row">
            <div className="col-10">
                <ShinyLoader width={'100%'} height="16px" />
            </div>
        </div>
    </div>
)

const CustomerTicketsSkeleton = () => {
    return (
        <div>
            <CustomerTicketsSkeletonElement />
            <CustomerTicketsSkeletonElement />

            <div className="row justify-content-center mt-5">
                <div className="col-md-10">
                    <ShinyLoader width={"100%"} height="55px" />
                </div>
            </div>
        </div>
    );
};

export default CustomerTicketsSkeleton;