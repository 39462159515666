import { useEffect, useState } from "react";
import { apiRoute } from "services";
import API from "services/lib/api";
import { mimeTypes } from "utils/mime";


const ModalPreviewFile = ({ fileName, fileType, preview, file }) => {

    const [mimeType, setMimeType] = useState("");

    const [blob, setBlobUrl] = useState("")

    const imageUrlToBase64 = async (url) => {
        return await fetch(url)
            .then((response) => response.text())

    };

    const base64toBlob = async (data) => {
        // Cut the prefix `data:application/pdf;base64` from the raw base 64
        const base64result = await imageUrlToBase64(preview);
        const base64Str = base64result.toString().replace(/^data:(.*,)?/, "");

        const fileName = file?.filename || file?.fileAttachmentName;
        const fileExtension = fileName?.split('.').pop()
        const mimeType = mimeTypes[fileExtension]
        setMimeType(mimeType);
        setBlobUrl(`data:${mimeType};base64,${base64Str}`);
        // ;
    };

    const casualFile = () => {

        const fileName = file?.filename || file?.fileAttachmentName;
        const fileExtension = fileName?.split('.').pop()
        const mimeType = mimeTypes[fileExtension]
        setMimeType(mimeType);
        setBlobUrl(preview)
    }

    const getFileUrl = async () => {
        try {
            const url = apiRoute.getFileLink;

            const res = await API.get(url, {
                params: {
                    fileKey: preview,
                },
            });

            const data = res.data;
            const fileName = file?.filename || file?.fileAttachmentName;
            const fileExtension = fileName?.split('.').pop()
            const mimeType = mimeTypes[fileExtension];

            setMimeType(mimeType);
            setBlobUrl(data?.data);

        } catch (err) {
            
        }
    };

    const handleFileSrc = () => {
        if (file?.isEmailLink) {
            base64toBlob();
            return;
        } else {
            preview.startsWith("https") ? casualFile() : getFileUrl();
        }
    };


    useEffect(() => {
        handleFileSrc();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="row justify-content-center">
            <div className="col-md-10">
                <embed key={blob} width="100%" height="auto" src={`${blob}`}
                    type={mimeType}
                />
            </div>
        </div>
    );
};

export default ModalPreviewFile;
