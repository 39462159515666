import { useState, useContext, useEffect } from "react";
import { ReactSVG } from "react-svg";
import asset from "assets/images";
import TicketAAInfoCard from "../../common/TicketAAInfoCard/TicketAAInfoCard";
import { SEND_AGENT_REPLY } from "services/socket/events";
import {
    appMessageUserTypes,
    messageTypes
} from "components/dashboard/AgentInbox/AgentInboxTickets/ChatTickets/ChatModule/LiveChat/MessageBody/Messages/Message/enums";
import { useDispatch, useSelector } from "react-redux";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import { Checkbox } from "components/ui";
import { useSocket } from "services/socket/hook";
import { saveTicketsMessages, updateTicketMessage } from "store/tickets/actions";
import { generateID } from "utils/helper";
import { MESSAGE_STATUSES } from "../../../../ChatModule/LiveChat/enum";

const { CANNED_SCENAIRO } = messageTypes;

const { WORKSPACE_AGENT } = appMessageUserTypes;
const { SENDING, FAILED, SENT } = MESSAGE_STATUSES;

const TicketAAScenarioDetail = ({
    scenario,
    sentScenairos,
    handleCannedActionSuccess,
    ticketConversation,
}) => {
    const [isOpen, toggleOpen] = useState(false);
    const { activeTicket } = useSelector((state) => state.tickets);

    const socket = useSocket();
    const toastMessage = useContext(ToastContext);
    const dispatch = useDispatch();

    const [messageContent, setMessageContent] = useState(
        scenario?.guideScenairoMessage
    );
    const hasBeenSent = sentScenairos.findIndex((x) => x.ticketConversationId === scenario.guideScenairoId) >= 0;

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            !socket?.connected && socket?.connect()
        }, 3000);

        return () => clearTimeout(delayDebounceFn);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socket])

    const handleSendScenarioMessage = async (message) => {
        // if (!Boolean(socket?.connected)) {
        //     toastMessage("Reconnect to send scenario to chat", ERROR);
        // }


        const { ticketId } = activeTicket;
        const messageId = generateID();
        const cannedResourceId = scenario?.guideScenairoId;

        if (!socket?.connected) {
            await socket?.connect();
        }
        dispatch(
            saveTicketsMessages({
                ticketId,
                senderType: WORKSPACE_AGENT,
                messageContentId: cannedResourceId,
                messageId,
                messageContent,
                messageType: CANNED_SCENAIRO,
                fileAttachments: [],
                status: SENDING,
            })
        );

        const socketEventCallBack = (socketResponse, socketEvent, data) => {
            if (socketResponse?.status !== "ok") {
                dispatch(
                    updateTicketMessage({
                        messageContentId: cannedResourceId,
                        ticketId,
                        status: FAILED,
                    })
                );

            } else {
                dispatch(
                    updateTicketMessage({
                        messageId,
                        messageContentId: cannedResourceId,
                        ticketId,
                        status: SENT,
                    })
                );
                handleCannedActionSuccess?.({
                    createdDate: "",
                    updatedDate: "",
                    ticketConversationId: scenario?.guideScenairoId,
                    ticketConversationSenderId: "",
                    ticketConversationSenderType: "AGENT",
                    conversationContext: "AGENT",
                    ticketConversationType: CANNED_SCENAIRO,
                });
                toastMessage("Scenario sent to chat");
            }
        };

        await socket.emit(SEND_AGENT_REPLY, {
            ticketId: activeTicket?.ticketId,
            message: messageContent,
            messageType: CANNED_SCENAIRO,
            messageContentId: scenario?.guideScenairoId,
        }, socketEventCallBack);

    };

    const findAScenario = ticketConversation?.find?.(
        ({ ticketConversationId, ticketConversationType }) =>
            ticketConversationId === scenario?.guideScenairoId &&
            ticketConversationType === CANNED_SCENAIRO
    );

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            !socket?.connected && socket?.connect()
        }, 1000);

        return () => clearTimeout(delayDebounceFn);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socket])

    return (
        <>
            {hasBeenSent ? <div className="ticketaa-response-list__select checked">
                <span>{scenario?.guideScenairoName}</span>
                <Checkbox checked />
            </div> :
                <div className='ticketaa-scenarios__content'>
                    <div
                        className={`ticketaa-scenarios__content__heading`}
                        onClick={() => toggleOpen(!isOpen)}>
                        <h6>{scenario?.guideScenairoName}</h6>
                        <ReactSVG
                            src={isOpen ? asset.icons.upside : asset.icons.downside}
                        />
                    </div>
                    {isOpen && (
                        <TicketAAInfoCard
                            borderColor={"#6837EF"}
                            handleChange={setMessageContent}
                            text={messageContent}
                            handleSend={() =>
                                handleSendScenarioMessage(
                                    scenario?.guideScenairoMessage
                                )
                            }
                            disabled={
                                Boolean(findAScenario)
                            }
                            disabledText={
                                findAScenario
                                    ? ""
                                    : "Your internet connectivity is poor"
                            }
                        />
                    )}
                </div>
            }
        </>
    );
};

export default TicketAAScenarioDetail;
