import { timeSince, truncateString } from "utils/helper";
import "./Ticket.scss";
import TicketInteractionStatus from "./TicketInteractionStatus/TicketInteractionStatus";

const Ticket = ({
    data,
    isActive = false,
    handleTicketSelect,
    agents,
    authUserId,
}) => {
    const {
        customer,
        ticketId,
        messages,
        updatedDate,
        assignedTeamMemberId,
        mentioned,
    } = data || {};
    const { email, appMessageUserId, userId } = customer || {};

    const ticketCreatedSince = timeSince(updatedDate);

    return (
        <div
            key={ticketCreatedSince}
            className={`customer__ticket ${isActive && "active"}`}
            id={`${ticketId}`}
            onClick={(e) => {
                e.stopPropagation();
                handleTicketSelect(ticketId);
            }}>
            <div className='email__time row align-items-center'>
                <div className='col-8'>
                    <p className='customer__email'>
                        {truncateString(
                            email || appMessageUserId || userId,
                            15
                        )}
                        {ticketCreatedSince === "Now" && (
                            <span className='round__circle' />
                        )}{" "}
                    </p>
                </div>
                <div className='col-4 text-right'>
                    <span className='since__when'>{ticketCreatedSince}</span>
                </div>
            </div>

            <TicketInteractionStatus
                {...{
                    assignedTeamMemberId,
                    mentioned,
                    customer,
                    ticketId,
                    isActive,
                    messages,
                    agents,
                    authUserId,
                }}
            />
        </div>
    );
};

export default Ticket;
