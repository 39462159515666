import * as types from "./types";

const initialState = {
    ticketsfilterRequest: {
        isFetching: false,
        ticketPhase: undefined,
        currentPage: 1,
        pageSize: 100,
        all: undefined,
        lastModified: "desc",
        hasMore: false,
    },
    authTicketInfo: [],
};

const TicketsReducer = (state = initialState, { type, payload }) => {
    switch (type) {

        case types.UPDATE_TICKETS_FILTERS:
            const { filterName, filterValue, filterOption } = payload || {};
            return {
                ...state,
                ticketsfilterRequest: {
                    ...state?.ticketsfilterRequest,
                    [filterName]:
                        state?.ticketsfilterRequest?.[filterName] ===
                            filterValue
                            ? undefined
                            : filterValue,
                    otherFilter: filterOption,
                },
            };

        case types.SAVE_AUTH_TICKET__INFO:
            return {
                ...state,
                authTicketInfo: payload,
            };

        case types.RESET_TICKETS_PAGINATION_FILTERS:
            return {
                ...state,
                ticketsfilterRequest: {
                    ...state?.ticketsfilterRequest,
                    currentPage: 1,
                    isFetching: false,
                    hasMore: false,
                },
            };

        case types.SET_TICKETS_META:
            return {
                ...state,
                ticketsfilterRequest: {
                    ...state.ticketsfilterRequest,
                        totalRecords: payload.totalRecords,
                        hasMore: payload.hasMore
                }
            };

        case types.SET_TICKETS_LOADING:
            return {
                ...state,
                ticketsfilterRequest: {
                    ...state.ticketsfilterRequest,
                    isFetching: payload
                }
            };

        default:
            return state;

    }
};

export default TicketsReducer;
