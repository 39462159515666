// eslint-disable-next-line import/no-anonymous-default-export

const imageLinks = {
    images: {
        optionCollection:
            "https://res.cloudinary.com/metacare/image/upload/v1654082598/Vector_h5ehln.svg",
        emptyCollection:
            "https://res.cloudinary.com/metacare/image/upload/v1654033057/Vector_flqr3l.svg",
        branch: "https://res.cloudinary.com/metacare/image/upload/v1654032007/Group_uev5ay.svg",
        logoLight:
            "https://res.cloudinary.com/metacare/image/upload/v1634827853/Group_1000001435_2_cvtzwv.svg",
        logoDark:
            "https://res.cloudinary.com/metacare/image/upload/v1634747254/Group_1000001435_1_tcmckw.svg",
        logoNeutral:
            "https://res.cloudinary.com/metacare/image/upload/v1635256403/Group_1000001435_3_ib5mbz.svg",
        sideLogo:
            "https://res.cloudinary.com/metacare/image/upload/v1636539973/Rectangle_5219_tawbwu.svg",
        collapsedLogo:
            "https://res.cloudinary.com/metacare/image/upload/v1636541162/Group-8132_f3uso2.svg",
        note: "https://res.cloudinary.com/metacare/image/upload/v1656519303/Vector_3_bre6el.svg",
        anchorRight:
            "https://res.cloudinary.com/metacare/image/upload/v1636916282/Shape_y9ynvm_hdksfq.svg",
        anchorDown:
            "https://res.cloudinary.com/metacare/image/upload/v1636916282/Shape_1_lyitwd_pj097l.svg",
        bell: "https://res.cloudinary.com/metacare/image/upload/v1636556601/Shape_n7zxms.svg",
        profile:
            "https://res.cloudinary.com/metacare/image/upload/v1636556869/ahmad_masqdg.png",
        peopleImg:
            "https://res.cloudinary.com/metacare/image/upload/v1636728489/people_ly2uvg.png",
        messages:
            "https://res.cloudinary.com/metacare/image/upload/v1636021007/ic_Settings_hvgjkp.svg",
        modalClose:
            "https://res.cloudinary.com/metacare/image/upload/v1637163654/Group_8469_x8iiw2.svg",
        demoAvi:
            "https://res.cloudinary.com/metacare/image/upload/v1637310112/bogdan2_3_eoawot.png",
        back: "https://res.cloudinary.com/metacare/image/upload/v1637439643/Group_1000002167_azbkyp.svg",
        placeholder:
            "https://res.cloudinary.com/metacare/image/upload/v1637750335/Ellipse_1_jilecl.png",
        emptyMssg:
            "https://res.cloudinary.com/metacare/image/upload/v1643928038/icons/Group_1000002714_ifcitz.svg",
    },
    icons: {
        loadMore: "https://res.cloudinary.com/metacare/image/upload/v1642339577/icons/icon_n7beax.svg",
        downTimeArrow: `https://res.cloudinary.com/metacare/image/upload/v1710673541/icons/up_f9rqsr.svg`,
        uptimeArrow: `https://res.cloudinary.com/metacare/image/upload/v1710673541/icons/down_ddfokn.svg`,
        attachmentIcon: `https://res.cloudinary.com/metacare/image/upload/v1660633678/Shape_xbc2g3.svg`,
        sendIcon: `https://res.cloudinary.com/metacare/image/upload/v1655970938/icons/Send_nputha.svg`,
        shapeCaret: `https://res.cloudinary.com/metacare/image/upload/v1655935882/icons/Shape_sz4gmc.svg`,
        emptyBuzz: `https://res.cloudinary.com/metacare/image/upload/v1655881448/icons/Vector_2_ith8ta.svg`,
        idleIcon:
            "https://res.cloudinary.com/metacare/image/upload/v1657284207/icons/clarity_tag-line_etqclu.svg",
        simpleAnchor: `https://res.cloudinary.com/metacare/image/upload/v1655259288/icons/Vector_zbhmei.svg`,
        A: `https://res.cloudinary.com/metacare/image/upload/v1652483375/icons/A_rf41mu.svg`,
        asterick: `https://res.cloudinary.com/metacare/image/upload/v1652483432/icons/yrwqohfzljmtcvqchhec.svg`,
        bluePlusIcon:
            "https://res.cloudinary.com/metacare/image/upload/v1646119830/icons/plus_1_migdel.svg",
        addPlusIcon:
            "https://res.cloudinary.com/metacare/image/upload/v1645958973/icons/plus_jqwp1c.svg",
        crossIcon:
            "https://res.cloudinary.com/metacare/image/upload/v1645539369/cross_hoh4md.svg",
        crossIconGrey:
            "https://res.cloudinary.com/metacare/image/upload/v1645569938/cross_1_f1infl.svg",
        rightBlack:
            "https://res.cloudinary.com/metacare/image/upload/v1646290197/icons/arrow_2_xwfznl.svg",
        chat_icon:
            "https://res.cloudinary.com/metacare/image/upload/v1655681382/icons/Vector_1_qclow2.svg",
        addUser:
            "https://res.cloudinary.com/metacare/image/upload/v1642336939/icons/icon_uqh3lc.svg",
        plusWhite:
            "https://res.cloudinary.com/metacare/image/upload/v1642337416/icons/icon_d3lsxp.svg",
        boldWhitePlus:
            "https://res.cloudinary.com/metacare/image/upload/v1662335224/icons/plus_r5rtie.svg",
        upload: "https://res.cloudinary.com/metacare/image/upload/v1642337926/icons/icon_fxns1z.svg",
        bin: "https://res.cloudinary.com/metacare/image/upload/v1642338169/icons/icon_kaxpcz.svg",
        binCircle:
            "https://res.cloudinary.com/metacare/image/upload/v1642338242/icons/icon_ic5sn6.svg",
        cancelX:
            "https://res.cloudinary.com/metacare/image/upload/v1642338302/icons/icon_x3uj2c.svg",
        video: "https://res.cloudinary.com/metacare/image/upload/v1642338377/icons/icon_sg9sta.svg",
        picture:
            "https://res.cloudinary.com/metacare/image/upload/v1642338449/icons/icon_xhycbp.svg",
        calender:
            "https://res.cloudinary.com/metacare/image/upload/v1642339261/icons/Vector_1_zltwqz.svg",
        calenderBlank:
            "https://res.cloudinary.com/metacare/image/upload/v1642338562/icons/icon_nwb9c5.svg",
        attach: "https://res.cloudinary.com/metacare/image/upload/v1642338772/icons/icon_o8grqy.svg",
        clipboard:
            "https://res.cloudinary.com/metacare/image/upload/v1642338866/icons/icon_hylznv.svg",
        carbonMore:
            "https://res.cloudinary.com/metacare/image/upload/v1646152625/icons/carbon_overflow-menu-vertical_svqjv4.svg",
        dotsMenu:
            "https://res.cloudinary.com/metacare/image/upload/v1642338947/icons/icon_yymymq.svg",
        harmburger:
            "https://res.cloudinary.com/metacare/image/upload/v1642339049/icons/icon_pukbil.svg",
        ellipsis:
            "https://res.cloudinary.com/metacare/image/upload/v1642339294/icons/icon_f7oljz.svg",
        menuArrow:
            "https://res.cloudinary.com/metacare/image/upload/v1642339360/icons/icon_iade0v.svg",
        dropdown:
            "https://res.cloudinary.com/metacare/image/upload/v1642339417/icons/icon_fhktss.svg",
        anchorDown:
            "https://res.cloudinary.com/metacare/image/upload/v1642339577/icons/icon_n7beax.svg",
        horizontalEllipsis:
            "https://res.cloudinary.com/metacare/image/upload/v1642339500/icons/icon_wocjvm.svg",
        anchorRight:
            "https://res.cloudinary.com/metacare/image/upload/v1642339971/icons/icon_wwrw7e.svg",
        anchorRightColored:
            "https://res.cloudinary.com/metacare/image/upload/v1642340020/icons/icon_bykk9n.svg",
        cancel: "https://res.cloudinary.com/metacare/image/upload/v1642339646/icons/icon_mcrqgt.svg",
        verticalLine:
            "https://res.cloudinary.com/metacare/image/upload/v1642339914/icons/icon_ow3eum.svg",
        mssgAlert:
            "https://res.cloudinary.com/metacare/image/upload/v1642340068/icons/icon_rbefsu.svg",
        info: "https://res.cloudinary.com/metacare/image/upload/v1642340118/icons/icon_qhm1j3.svg",
        blueInfo:
            "https://res.cloudinary.com/metacare/image/upload/v1662334207/icons/Group_1000003118_maul7d.svg",
        indicator:
            "https://res.cloudinary.com/metacare/image/upload/v1660165846/icons/Group_1000003017_h3ifeo.svg",
        back: "https://res.cloudinary.com/metacare/image/upload/v1642340199/icons/icon_ws4ekl.svg",
        search: "https://res.cloudinary.com/metacare/image/upload/v1642340261/icons/icon_lifhdq.svg",
        dot: "https://res.cloudinary.com/metacare/image/upload/v1642340306/icons/icon_fecgyk.svg",
        check: "https://res.cloudinary.com/metacare/image/upload/v1642340363/icons/icon_yfq9tn.svg",
        tooltip:
            "https://res.cloudinary.com/metacare/image/upload/v1642340431/icons/icon_ccoomg.svg",
        link: "https://res.cloudinary.com/metacare/image/upload/v1642340482/icons/icon_mt2jpa.svg",
        arrowRight:
            "https://res.cloudinary.com/metacare/image/upload/v1642340527/icons/icon_rtjnq3.svg",
        checkLine:
            "https://res.cloudinary.com/metacare/image/upload/v1642340587/icons/icon_uclk3g.svg",
        checkGrey:
            "https://res.cloudinary.com/metacare/image/upload/v1642340637/icons/icon_q9sfd7.svg",
        uptime: "https://res.cloudinary.com/metacare/image/upload/v1642340677/icons/icon_cmtmdc.svg",
        downtime:
            "https://res.cloudinary.com/metacare/image/upload/v1642340715/icons/icon_sbtjgq.svg",
        average:
            "https://res.cloudinary.com/metacare/image/upload/v1642340762/icons/icon_agsjzk.svg",
        plus: "https://res.cloudinary.com/metacare/image/upload/v1642626690/icons/icon_vonhuy.svg",
        error: "https://res.cloudinary.com/metacare/image/upload/v1643030939/icons/icon_xdmgjv.svg",
        trashIcon:
            "https://res.cloudinary.com/metacare/image/upload/v1652702243/icons/trash_k9qpc7.svg",
        noConvosIcon:
            "https://res.cloudinary.com/metacare/image/upload/v1660030280/Group_t26xzn.svg",
        reset: "https://res.cloudinary.com/metacare/image/upload/v1657283767/icons/Group_1000003068_ycvxrb.svg",
        darkCancel:
            "https://res.cloudinary.com/metacare/image/upload/v1657790920/icons/cross_vsq3wq.svg",
        mutedDot:
            "https://res.cloudinary.com/metacare/image/upload/v1657795922/icons/dot_wukmfw.svg",
        product_uptime:
            "https://res.cloudinary.com/metacare/image/upload/v1657818561/icons/uptime_bzurxp.svg",
        chat: "https://res.cloudinary.com/metacare/image/upload/v1657818561/icons/chat_xnjkhc.svg",
        capsule:
            "https://res.cloudinary.com/metacare/image/upload/v1657818560/icons/capsule_wiwo4b.svg",
        branch: "https://res.cloudinary.com/metacare/image/upload/v1657818560/icons/branch_zvi06w.svg",
        product_downtime:
            "https://res.cloudinary.com/metacare/image/upload/v1657818560/icons/downtime_xr6csq.svg",
        emptyNotification:
            "https://res.cloudinary.com/metacare/image/upload/v1658144049/icons/empty_notification_qb9oro.svg",
        dragIcon:
            "https://res.cloudinary.com/metacare/image/upload/v1658249262/icons/dragIcon_tj06kk.svg",
        redDot: "https://res.cloudinary.com/metacare/image/upload/v1658478725/icons/red-dot_ias8pg.svg",
        remove: "https://res.cloudinary.com/metacare/image/upload/v1659960601/icons/remove_glyhiz.svg",
        document:
            "https://res.cloudinary.com/metacare/image/upload/v1659960640/icons/document_oojnni.svg",
        play: "https://res.cloudinary.com/metacare/image/upload/v1659960678/icons/play_e7fdhn.svg",
        loading:
            "https://res.cloudinary.com/metacare/image/upload/v1659970761/icons/loading_c47v6s.svg",
        retry: "https://res.cloudinary.com/metacare/image/upload/v1660125326/icons/Group_1000003017_h4hzpp.svg",
        abort: "https://res.cloudinary.com/metacare/image/upload/v1660125321/icons/Group_1000003016_qnwr0r.svg",
        comment:
            "https://res.cloudinary.com/metacare/image/upload/v1663103236/icons/Vector_ltzlji.svg",
        emoji: "https://res.cloudinary.com/metacare/image/upload/v1663103234/icons/emoji_wrsydk.svg",
        lightCancel:
            "https://res.cloudinary.com/metacare/image/upload/v1663167559/icons/Vector_1_w4yhgr.svg",
        lightCheck:
            "https://res.cloudinary.com/metacare/image/upload/v1663168507/icons/Vector_2_dhtnkj.svg",
        editNote:
            "https://res.cloudinary.com/metacare/image/upload/v1663309470/icons/clarity_note-edit-line_mghhnn.svg",
        task: "https://res.cloudinary.com/metacare/image/upload/v1663313019/icons/carbon_task-complete_zoeyci.svg",
        slantUpArrow:
            "https://res.cloudinary.com/metacare/image/upload/v1663326238/icons/Vector_dv6mme.svg",
        slantDownArrow:
            "https://res.cloudinary.com/metacare/image/upload/v1663326238/icons/down_arrow_yc2mpj.svg",
        clock: "https://res.cloudinary.com/metacare/image/upload/v1663345730/icons/Vector_1_pdubjj.svg",
        sendIconv2:
            "https://res.cloudinary.com/metacare/image/upload/v1649858641/icons/Shape_vilthf.svg",
        emoji3d:
            "https://res.cloudinary.com/metacare/image/upload/v1663432791/icons/rencni5av9jkl1msrhw9.svg",
        text: "https://res.cloudinary.com/metacare/image/upload/v1663432793/icons/Vector_fdhxlk.svg",
        scheduleSend:
            "https://res.cloudinary.com/metacare/image/upload/v1663432795/icons/ic_outline-schedule-send_2x_h4fgvf.svg",
        arrowUp:
            "https://res.cloudinary.com/metacare/image/upload/v1664227815/icons/eva_arrowhead-up-fill_lcqwgs.svg",
        arrowDown:
            "https://res.cloudinary.com/metacare/image/upload/v1664227857/icons/eva_arrowhead-up-fill_1_agbwev.svg",
        control:
            "https://res.cloudinary.com/metacare/image/upload/v1665596111/icons/control_pouvn6.svg",
        tinyArrowLeft:
            "https://res.cloudinary.com/metacare/image/upload/v1665493802/icons/Vector_2_aqaedc.svg",
        tinyArrowRight:
            "https://res.cloudinary.com/metacare/image/upload/v1665493727/icons/Vector_2_blessy.svg",
        dotCircle:
            "https://res.cloudinary.com/metacare/image/upload/v1665683261/icons/Ellipse_9_atxrsx.svg",
        circledQuest:
            "https://res.cloudinary.com/metacare/image/upload/v1665683304/icons/fi-rr-interrogation_hwqh68.svg",
        cloud: "https://res.cloudinary.com/metacare/image/upload/v1666271380/icons/fi-rr-cloud-upload_cgoi89.svg",
        image: "https://res.cloudinary.com/metacare/image/upload/v1666370471/icons/fi-rr-picture_bbzrj0.svg",
        curveArrowRight:
            "https://res.cloudinary.com/metacare/image/upload/v1666861641/icons/Vector_1_fnknob.svg",
        curveArrowLeft:
            "https://res.cloudinary.com/metacare/image/upload/v1666861639/icons/Vector_zzaqvb.svg",
        cloudUpload:
            "https://res.cloudinary.com/metacare/image/upload/v1667225934/icons/Vector_x4nt3t.svg",
        pipe: "https://res.cloudinary.com/metacare/image/upload/v1668628692/icons/Vector_28_yuskqm.svg",
        redTrash:
            "https://res.cloudinary.com/metacare/image/upload/v1668628727/icons/fi-rr-trash_gt1oxu.svg",
        funnelFilter:
            "https://res.cloudinary.com/metacare/image/upload/v1670947719/icons/fluent_filter-28-regular_fnpdtz.svg",
        successCheck:
            "https://res.cloudinary.com/metacare/image/upload/v1680852130/Frame_1000003452_pek73z.svg",
        lightModeToggle:
            "https://res.cloudinary.com/metacare/image/upload/v1686243055/Vector_1_z1z6sk.svg",
        darkModeMoon:
            "https://res.cloudinary.com/metacare/image/upload/v1686242854/Vector_hzgdix.svg",
        lightModeMoon:
            "https://res.cloudinary.com/metacare/image/upload/v1686243323/Vector_2_nh6fy9.svg",
        darkModeToggle:
            "https://res.cloudinary.com/metacare/image/upload/v1686242884/ion_switch_nffloo.svg",
        greenArrowUP:
            "https://res.cloudinary.com/metacare/image/upload/v1688947172/Group_6_gdsnan.svg",
        redArrowDown:
            "https://res.cloudinary.com/metacare/image/upload/v1688947161/Group_6_lzuoap.svg",
        arrowLeft:
            "https://res.cloudinary.com/metacare/image/upload/v1699258267/ph_arrow-up_iu2s8q.svg",
        pinDown:
            "https://res.cloudinary.com/metacare/image/upload/v1699258513/carbon_pin_kfata0.svg",
        viewEye:
            "https://res.cloudinary.com/metacare/image/upload/v1699258755/ep_view_v74wtz.svg",
        upside: "https://res.cloudinary.com/metacare/image/upload/v1703080424/ic_Chevron_z790hm.svg",
        downside:
            "https://res.cloudinary.com/metacare/image/upload/v1703080386/Shape_plt6cd.svg",
        mark: "https://res.cloudinary.com/metacare/image/upload/v1705079952/Combined_Shape_aosdox.svg",
        tTimer: "https://res.cloudinary.com/metacare/image/upload/v1707361557/Vector_15_ctind8.svg",
        timer: "https://res.cloudinary.com/metacare/image/upload/v1705080009/material-symbols_alarm-outline_nmyttm.svg",
        pauseTimer:
            "https://res.cloudinary.com/metacare/image/upload/v1705418819/Vector_f9orxd.svg",
        continueTimer:
            "https://res.cloudinary.com/metacare/image/upload/v1705418821/Vector_1_s2oxct.svg",
        avatar: "https://res.cloudinary.com/metacare/image/upload/v1705996557/icons/png-clipart-default-profile-united-states-computer-icons-desktop-free-high-quality-person-icon-miscellaneous-silhouette-thumbnail_xmjmga.png",
    },
};

export default imageLinks;
