import { useDispatch, useSelector } from "react-redux";
import React, { useState, useMemo, useEffect } from "react";
import { ReactSVG } from "react-svg";
import imageLinks from "assets/images";
import debounce from "debounce-promise";
import { updateTicketsFilters } from "store/ticketsFilters/actions";
import SelectUI from "components/ui/SelectUI/SelectUI";
import "./TicketsSearch.scss";
import { apiRoute } from "services";
import API from "services/lib/api";
import { getErrorMessage } from "utils/helper";
import { setAgentsList } from "store/cache/agents/actions";
import ComponentAccessControl from "components/essentials/ComponentAccessControl/ComponentAccessControl";
import { baseResourceActions, baseResources } from "routes/enum";

const searchFilters = [
    { filter: "Email", placeholder: "Search by email ..." },
    { filter: "Date", placeholder: "Sort by date ..." },
    { filter: "Agent", placeholder: "Search by agent ..." },
];

const dateFilterOptions = [
    {
        label: "Most Recent",
        value: "ASC",
    },
    {
        label: "Least Recent",
        value: "DESC",
    },
];

const { USER_MANAGEMENT_ROLES, USER_MANAGEMENT_USERS } = baseResources;
const { CREATE } = baseResourceActions;

const TicketsSearch = () => {
    const [showFilters, toggleFilters] = useState(false);
    const [currentSearchFilter, setCurrentSearchFilter] = useState("");
    const [searchValue, setSearchValue] = useState("");

    const [showDateFilter, setShowDateFilter] = useState(false);
    const [showAgentFilter, setShowAgentFilter] = useState(false);
    const dispatch = useDispatch();
    const [errorMssg, setErrorMssg] = useState("");

    const { ticketsfilterRequest } = useSelector(
        (state) => state.ticketsFilters
    );

    const { agents } = useSelector((state) => state.agents);

    const { dateOrder, agentId, search } = ticketsfilterRequest;

    const handleRemoveSearchFilter = (e) => {
        e?.stopPropagation?.();
        setCurrentSearchFilter("");
        setSearchValue("");
        setShowAgentFilter(false);
        setShowDateFilter(false);
        dispatch(
            updateTicketsFilters({
                filterName: "search",
                filterValue: undefined,
            })
        );
        dispatch(
            updateTicketsFilters({
                filterName: "all",
                filterValue: undefined,
            })
        );
       
        dispatch(
            updateTicketsFilters({
                filterName: "dateOrder",
                filterValue: undefined,
            })
        );
        dispatch(
            updateTicketsFilters({
                filterName: "agentId",
                filterValue: undefined,
            })
        );
    };

    const handleSetSearchFilter = (searchFilter) => {
        const { filter } = searchFilter;

        setCurrentSearchFilter(searchFilter);

        // setSearchValue("");
        if (filter === "Email") {
            toggleFilters(false);
            setShowDateFilter(false);
            setSearchValue(search);
        } else if (filter === "Agent") {
            setShowAgentFilter(true);
            setShowDateFilter(false);
            setSearchValue(
                agents?.filter?.(({ value }) => value === agentId)?.[0]
                    ?.label || ""
            );
        } else {
            setShowDateFilter(true);
            setShowAgentFilter(false);
            setSearchValue(
                dateFilterOptions?.filter?.(
                    ({ value }) => value === dateOrder
                )?.[0]?.label || ""
            );
        }
    };

    const handleSelect = (label, value, isDate) => {
        setSearchValue(label);
        dispatch(
            updateTicketsFilters({
                filterName: "search",
                filterValue: undefined,
            })
        );
        dispatch(
            updateTicketsFilters({
                filterName: "dateOrder",
                filterValue: isDate ? value : undefined,
            })
        );
        dispatch(
            updateTicketsFilters({
                filterName: "agentId",
                filterValue: isDate ? undefined : value,
            })
        );
    };

    const handleSearch = (value) => {
        dispatch(
            updateTicketsFilters({
                filterName: "search",
                filterValue: value,
            })
        );
        dispatch(
            updateTicketsFilters({
                filterName: "all",
                filterValue: true,
            })
        );
        dispatch(
            updateTicketsFilters({
                filterName: "dateOrder",
                filterValue: undefined,
            })
        );
        dispatch(
            updateTicketsFilters({
                filterName: "agentId",
                filterValue: undefined,
            })
        );
    };

    const handleChange = ({ target: { value } }) => {
        setSearchValue(value);
        debouncedSearch(value);
    };

    const debouncedSearch = useMemo(
        () =>
            debounce((search) => handleSearch(search), 1000, { leading: true }),
        // eslint-disable-next-line
        []
    );

    const getAgents = async (httpRequest) => {
        try {
            setErrorMssg("");
            const url = apiRoute.getUsers;
            const res = await API.get(url, {
                params: { all: true },
                signal: httpRequest?.signal,
            });

            if (res.status === 200) {
                const { data } = res.data;
                dispatch(
                    setAgentsList(
                        data?.map(
                            ({ firstName, lastName, userId, ...rest }) => {
                                return {
                                    value: userId,
                                    label: `${firstName} ${lastName}`,
                                    firstName,
                                    lastName,
                                    userId,
                                    ...rest,
                                };
                            }
                        )
                    )
                );
            }
        } catch (err) {
            setErrorMssg(getErrorMessage(err));
        }
    };

    // useEffect(() => {
    //     document.body.addEventListener("click", () => toggleFilters(false));

    //     return () =>
    //         document.body.removeEventListener("click", () =>
    //             toggleFilters(false)
    //         );
    // }, []);

    useEffect(() => {
        if (agentId || dateOrder || search) {
            handleSetSearchFilter({
                filter: agentId ? "Agent" : search ? "Email" : "Date",
            });
        }
        // eslint-disable-next-line
    }, [agentId, dateOrder, search]);

    useEffect(() => {
        getAgents();
        // eslint-disable-next-line
    }, []);

    return (
        <div className='tickets__search__container me-auto'>
            <div
                className='tickets__search'
                onClick={(e) => {
                    e.stopPropagation();
                    toggleFilters(!showFilters);
                }}>
                <span className='tickets__text'>Tickets;</span>

                {currentSearchFilter && (
                    <div className='current__search__filter'>
                        <span className='current__search__filter__text'>
                            {currentSearchFilter?.filter}
                        </span>
                        <img
                            src={imageLinks?.icons?.cancelX}
                            className='remove__filter'
                            alt='Cancel Icon'
                            onClick={(e) => handleRemoveSearchFilter(e)}
                        />
                    </div>
                )}
                <div className='tickets__search__input__field'>
                    <input
                        className='tickets__search__input'
                        placeholder={currentSearchFilter?.placeholder}
                        onClick={(e) => e.stopPropagation()}
                        value={searchValue}
                        defaultValue={searchValue}
                        onChange={handleChange}
                        disabled={
                            !currentSearchFilter?.filter ||
                            showDateFilter ||
                            showAgentFilter
                        }
                    />
                </div>
                <div className='tickets__search__actions'>
                    <ReactSVG
                        src={imageLinks?.icons?.funnelFilter}
                        onClick={() => toggleFilters(!showFilters)}
                    />
                    <ReactSVG
                        src={imageLinks?.icons?.search}
                        onClick={(e) => {
                            e.stopPropagation();
                            searchValue?.length > 0 &&
                                handleSearch(searchValue);
                        }}
                    />
                </div>
            </div>
            {showFilters && (
                <div className='tickets__search__filters'>
                    <span className='tickets__search__filters__text'>
                        Filter
                    </span>
                    <div className='tickets__search__filters__list'>
                        {searchFilters?.map((searchFilter, i) => (
                            <ComponentAccessControl
                                baseResources={[
                                    `${CREATE}_${USER_MANAGEMENT_ROLES}`,
                                    `${CREATE}_${USER_MANAGEMENT_USERS}`,
                                ]}
                                key={i}
                                ignorePermissions={i !== 2}>
                                <div
                                    className={`tickets__search__filter ${
                                        searchFilter?.filter ===
                                        currentSearchFilter?.filter
                                            ? "tickets__search__filter__active"
                                            : ""
                                    }`}
                                    onClick={() =>
                                        handleSetSearchFilter(searchFilter)
                                    }>
                                    {searchFilter?.filter}
                                </div>
                            </ComponentAccessControl>
                        ))}
                        {(showDateFilter || showAgentFilter) && (
                            <SelectUI
                                otherClassNames='tickets__search__date__filter'
                                placeholder={
                                    showDateFilter
                                        ? "Select Order"
                                        : "Select Agent"
                                }
                                options={
                                    showDateFilter ? dateFilterOptions : agents
                                }
                                value={(showDateFilter
                                    ? dateFilterOptions
                                    : agents
                                )?.filter(({ value }) =>
                                    showDateFilter
                                        ? value === dateOrder
                                        : value === agentId
                                )}
                                onChange={({ value, label }) =>
                                    handleSelect(label, value, showDateFilter)
                                }
                                error={Boolean(errorMssg)}
                            />
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default TicketsSearch;
