import TicketAARunCard from "../common/TicketAARunCard/TicketAARunCard";
import TicketAAInfoCard from "../common/TicketAAInfoCard/TicketAAInfoCard";
import TicketAACardHeading from "../common/TicketAACardHeading/TicketAACardHeading";
import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import { SEND_AGENT_REPLY } from "services/socket/events";
import {
    appMessageUserTypes,
    messageTypes
} from "../../../ChatModule/LiveChat/MessageBody/Messages/Message/enums";
import TicketAATasksList from "../common/TicketAATasksList/TicketAATasksList";
import { generateID } from "utils/helper";

import "./TicketAAResponse.scss";
import { MESSAGE_STATUSES } from "../../../ChatModule/LiveChat/enum";
import { saveTicketsMessages, updateTicketMessage } from "store/tickets/actions";
import { useSocket } from "services/socket/hook";

const { WORKSPACE_AGENT } = appMessageUserTypes;
const { CANNED_RESPONSE, CANNED_UPDATE } = messageTypes;
const { SENDING, FAILED, SENT } = MESSAGE_STATUSES;

const TicketAAResponse = ({
    handleToggleEditTicketTaskModal,
    cannedResponse,
    selectedTicket,
    updateTasks = [],
    ticketConversation,
    handleCannedActionSuccess,
    guideFlow,
    hideIndicator,
    autoSentCR,
    sendsUpdate
}) => {
    const [open, toggleOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [cannedMessage, setCannedMessage] = useState(
        cannedResponse?.cannedResourceBody
    );

    const toastMessage = useContext(ToastContext);
    const socket = useSocket();
    const dispatch = useDispatch();

    const pausedTasks = updateTasks?.filter((ut) => ut.paused === true)
    let overalTaskStatuses = updateTasks?.reduce(function (r, a) {
        r[a.taskStatus] = (r[a.taskStatus] || 0) + 1;
        return r;
    }, {});

    let overalTaskStaus = pausedTasks.length > 0 ? ["PAUSED", pausedTasks.length] : Object.entries(overalTaskStatuses).reduce((max, entry) => entry[1] >= max[1] ? entry : max, [0, 0]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            !socket?.connected && socket?.connect()
        }, 1000);

        return () => clearTimeout(delayDebounceFn);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socket])

    const sendCannedResponseToChat = async () => {
        // if (!Boolean(socket?.connected)) {
        //     toastMessage("Reconnect to send canned Response to chat", ERROR);
        // }

        if (!cannedMessage) {
            return;
        }
        setLoading(true);

        const { ticketId } = selectedTicket;
        const message = cannedMessage || cannedResponse?.cannedResourceBody;
        const messageId = generateID();
        const cannedResourceId = sendsUpdate ? guideFlow.guideFlowId : cannedResponse?.cannedResourceId;

        if (!socket?.connected) {
            await socket?.connect();
        }
        dispatch(
            saveTicketsMessages({
                ticketId,
                senderType: WORKSPACE_AGENT,
                messageContentId: cannedResourceId,
                messageId,
                messageContent: message,
                messageType: CANNED_RESPONSE,
                fileAttachments: [],
                status: SENDING,
            })
        );

        const socketEventCallBack = (socketResponse, socketEvent, data) => {
            if (socketResponse?.status !== "ok") {
                dispatch(
                    updateTicketMessage({
                        messageContentId: cannedResourceId,
                        ticketId,
                        status: FAILED,
                    })
                );

            } else {
                dispatch(
                    updateTicketMessage({
                        messageId,
                        messageContentId: cannedResourceId,
                        ticketId,
                        status: SENT,
                    })
                );
                handleCannedActionSuccess?.({
                    createdDate: "",
                    updatedDate: "",
                    ticketConversationId: cannedResourceId,
                    ticketConversationSenderId: "",
                    ticketConversationSenderType: "AGENT",
                    conversationContext: "AGENT",
                    ticketConversationType: sendsUpdate ? CANNED_UPDATE : CANNED_RESPONSE,
                });
                toastMessage("Canned Response sent to chat");
                setLoading(false);
                toggleOpen(false)

            }
        };
        await socket.emit(SEND_AGENT_REPLY, {
            ticketId,
            message,
            messageType: sendsUpdate ? CANNED_UPDATE : CANNED_RESPONSE,
            messageId,
            messageContentId: cannedResourceId,
        }, socketEventCallBack);


    };

    const { cannedResourceId, cannedResourceType } = cannedResponse;

    const hasCannedResponseBeenSent = autoSentCR || ticketConversation?.find(
        ({ ticketConversationId, ticketConversationType }) =>
            ticketConversationId === cannedResourceId &&
            ticketConversationType === cannedResourceType
    );

    return (
        <section className='ticketaaresponse'>
            <TicketAACardHeading
                title='Response'
                onClick={() => toggleOpen(!open)}
                isOpen={open}
                showDot={!Boolean(hasCannedResponseBeenSent)}
                showMark={Boolean(hasCannedResponseBeenSent)}
            />
            {open && (
                <>
                    {cannedResponse && !Boolean(hasCannedResponseBeenSent) ? (
                        <>
                            <TicketAAInfoCard
                                text={cannedMessage}
                                title={cannedResponse?.cannedResourceName}
                                handleSend={sendCannedResponseToChat}
                                loading={loading}
                                handleChange={setCannedMessage}
                                disabled={
                                    Boolean(hasCannedResponseBeenSent)
                                }
                                disabledText={
                                    Boolean(hasCannedResponseBeenSent)
                                        ? ""
                                        : ""
                                }

                            />
                            {updateTasks.length > 0 && <TicketAARunCard
                                {
                                ...{
                                    updateTasks,
                                    overalTaskStaus
                                }
                                }
                            />
                            }
                        </>
                    )
                        : (
                            <>

                                <TicketAATasksList
                                    handleToggleEditTicketTaskModal={
                                        handleToggleEditTicketTaskModal
                                    }
                                    overalTaskStaus={overalTaskStaus}
                                    updateTasks={updateTasks}
                                    cannedResponse={cannedResponse}
                                    successMssg={`Response Sent to User`}
                                    isUpdate={sendsUpdate}
                                />
                            </>
                        )}
                </>
            )}
        </section>
    );
};

export default TicketAAResponse;
