import React from "react";
import { useSelector } from "react-redux";
import { ReactSVG } from "react-svg";
import HeaderInfo from "../HeaderInfo/HeaderInfo";
import imageLinks from "assets/images";
import { Dropdown } from "components/ui";
import { baseResources, baseResourceActions } from "routes/enum";
import { TICKETS_HEADER_ACTIONS } from "../enum";
import { ticketPhases } from "components/dashboard/AgentInbox/enum";
import { UNDISCOVERED, AUTOMATED } from "components/dashboard/AgentInbox/enum";
import "./ChatSettingsToggler.scss";

const { AGENTS_INBOX_BASE_SETTING } = baseResources;
const { UPDATE, DELETE } = baseResourceActions;
const { MARK_TICKET_AS_READ, CLOSE_TICKET, SNOOZE_TICKET } = TICKETS_HEADER_ACTIONS;
const { ISSUE_DISCOVERY } = ticketPhases;

const ChatSettingsToggler = ({ handleActionModal, ticketPhase }) => {
    const { messageTagId, ticketInfoValue } = useSelector(
        (state) => state?.ticketsFilters?.ticketsfilterRequest
    );

    const options = [
        {
            value: SNOOZE_TICKET,
            label: "Snooze Ticket",
            baseResources: [`${DELETE}_${AGENTS_INBOX_BASE_SETTING}`],
        },
        {
            value: CLOSE_TICKET,
            label: "Close Ticket",
            baseResources: [`${DELETE}_${AGENTS_INBOX_BASE_SETTING}`],
        },
    ];

    if (
        (ticketPhase === ISSUE_DISCOVERY && messageTagId !== UNDISCOVERED) ||
        (ticketPhase !== ISSUE_DISCOVERY && ticketInfoValue !== AUTOMATED)
    ) {
        options.unshift({
            value: MARK_TICKET_AS_READ,
            label: "Mark as Read",
            baseResources: [`${UPDATE}_${AGENTS_INBOX_BASE_SETTING}`],
        });
    }

    return (
        <div className='chat__settings__toggler'>
            <Dropdown
                size='sm'
                placeholder={
                    <HeaderInfo>
                        <ReactSVG
                            className='icon__caret'
                            src={imageLinks?.icons?.shapeCaret}
                        />
                    </HeaderInfo>
                }
                ddValueClass='borderless'
                className='table__dropdown chat__settings__dropdown'
                ddOptionsClass='table__dropdown'
                menuAlign='end'
                anchor={false}
                changeValue={false}
                onChange={({ value }) => handleActionModal(value)}
                options={options}
            />
        </div>
    );
};

export default ChatSettingsToggler;
