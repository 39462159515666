import React, { useCallback, useEffect, useRef, useState } from "react";
import EmptyInfo from "components/ui/Empty/EmptyInfo/EmptyInfo";
import imageLinks from "assets/images";
import { useSelector, useDispatch } from "react-redux";
import { setActiveTicket, setTicketToLeavePhase } from "store/tickets/actions";
import Ticket from "./Ticket/Ticket";
import { CLOSED_TICKET, NEW_TICKET_UPDATE } from "services/socket/events";
import {
    deleteIncomingTicket,
    pushIncomingTicket,
} from "store/tickets/incomingTickets/actions";
import { selectNextTicket } from "./util/helper";
import { setSortedTickets } from "store/tickets/incomingTickets/actions";
import CustomScroll from "components/ui/CustomScroll/CustomScroll";
import { otherCategories } from "components/dashboard/AgentInbox/enum";
import { ISSUE_DISCOVERY } from "../../common/TicketStatus/enum";
import { useSocket } from "services/socket/hook";
import { updateTicketsFilters } from "store/ticketsFilters/actions";
import { debounce, sortTickets } from "utils/helper";
import { CustomerTicketsSkeletonElement } from "../CustomerTicketsSkeleton/CustomerTicketsSkeleton";
import useInfiniteScroll from "hooks/useInfiniteScroll";
import "./CustomerTickets.scss";


const { AGENT_ASSISTANCE } = otherCategories;
const CustomerTickets = ({
    data,
    handleRemoveEmailPreview,
    selectedTicketHeader,
    clearSelectedCustomerMessage,
}) => {

    const socket = useSocket() // Access the socket from context
    const dispatch = useDispatch();
    const scrollContainerRef = useRef(null);


    const { ticketsfilterRequest } = useSelector(
        (state) => state.ticketsFilters
    );
    const { currentPage } = ticketsfilterRequest;

    const { agents } = useSelector((state) => state.agents);
    const {
        user: { userId },
    } = useSelector((state) => state.auth);

    const { lastModified } = ticketsfilterRequest;

    const sortedTickets = sortTickets(data, lastModified);

    const { activeTicket, ticketToLeavePhase } = useSelector(
        (state) => state.tickets
    );

    const { ticketPhase: selectedTicketPhase, isFetching, hasMore } = useSelector(
        (state) => state?.ticketsFilters?.ticketsfilterRequest
    );

    const [trigger, setTrigger] = useState(false);
    const [closedTicket, setClosedTicket] = useState();
    const loadMoreDirection = lastModified === 'asc' ? 'top' : 'bottom';

    // Create debounced dispatch function
    const debouncedDispatch = useCallback((nextPage, callback) => {
        const debouncedFn = debounce(() => {
            dispatch(updateTicketsFilters({
                filterName: 'currentPage',
                filterValue: nextPage
            }));

            // Call the callback after dispatch
            if (callback) callback();
        }, 300);

        debouncedFn();
    }, [dispatch]);



    const handleTicketClose = (ticket) => {
        const parsedTicket = JSON.parse(ticket);

        setTrigger(!trigger);
        setClosedTicket(parsedTicket);
    };

    const isTicketOnAgentAssistancePlusIssueDiscoPhase =
        selectedTicketPhase === ISSUE_DISCOVERY &&
        selectedTicketHeader === AGENT_ASSISTANCE;

    const handleTicketUpdate = (updatedTicket) => {
        const parsedTicket = JSON.parse(updatedTicket);
        if (parsedTicket?.newTicketPhase) {
            if (isTicketOnAgentAssistancePlusIssueDiscoPhase) {
                // the goal is to prevent the ticket from leaving the screen if it changes phase while being engaged with a guide
                dispatch(
                    setTicketToLeavePhase({
                        ...parsedTicket,
                        previousPhase: ISSUE_DISCOVERY,
                    })
                );
            } else {
                selectNextTicket(
                    parsedTicket?.newTicketPhase,
                    sortedTickets,
                    (ticket, ticketId) => {
                        dispatch(setActiveTicket(ticket));
                        ticketId &&
                            dispatch(deleteIncomingTicket({ ticketId }));
                        setClosedTicket();
                    }
                );
            }
        }
    };

    const adjustScrollPosition = useCallback(() => {
        if (loadMoreDirection === 'top') {
            // Target the parent container that has overflow-y: scroll
            const container = document.querySelector('.customer__tickets--container');
            
            if (container) {
                
                // Force a small delay to ensure content is rendered
                setTimeout(() => {
                    container.scrollTo({
                        top: 50, // Scroll down by fixed amount
                        behavior: 'smooth'
                    });
                    
                 
                }, 100);
            }
        }
    }, [loadMoreDirection]);
    

    useEffect(() => {
        if (
            (activeTicket === null || activeTicket === undefined) &&
            sortedTickets?.length > 0
        ) {
            dispatch(setActiveTicket(sortedTickets[0]));
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        dispatch(setSortedTickets(sortedTickets));
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        socket?.on(CLOSED_TICKET, handleTicketClose);
        socket?.on(NEW_TICKET_UPDATE, handleTicketUpdate);
        return () => {
            socket?.off(CLOSED_TICKET);
            socket?.off(NEW_TICKET_UPDATE);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (closedTicket?.ticketStatus === false) {
            if (activeTicket?.ticketId === closedTicket?.ticketId) {
                if (isTicketOnAgentAssistancePlusIssueDiscoPhase) {
                    dispatch(
                        setTicketToLeavePhase({
                            ...closedTicket,
                            previousPhase: ISSUE_DISCOVERY,
                        })
                    );
                } else {
                    selectNextTicket(
                        closedTicket,
                        sortedTickets,
                        (ticket, ticketId) => {
                            dispatch(setActiveTicket(ticket));
                            ticketId &&
                                dispatch(deleteIncomingTicket({ ticketId }));
                            setClosedTicket();
                        }
                    );
                }
            }
        }
        //eslint-disable-next-line
    }, [trigger, closedTicket]);

    useEffect(() => {
        if (!isFetching && loadMoreDirection === 'top') {
            adjustScrollPosition();
        }
    }, [isFetching, adjustScrollPosition, loadMoreDirection]);

    const handleTicketSelect = (item) => {
        clearSelectedCustomerMessage?.();
        const ticketToLeavePhaseId = ticketToLeavePhase?.ticketId;

        if (ticketToLeavePhaseId && selectedTicketPhase === ISSUE_DISCOVERY) {
            dispatch(
                pushIncomingTicket({ ...ticketToLeavePhase, isNew: true })
            );
            dispatch(deleteIncomingTicket({ ticketId: ticketToLeavePhaseId }));
        }
        dispatch(setActiveTicket(item));
        handleRemoveEmailPreview?.();
    };

   
    const LoadingShell = () => (
        <>
            <CustomerTicketsSkeletonElement />
            <CustomerTicketsSkeletonElement />
        </>
    );

    const loadMoreTickets = useCallback(() => {
        if (!isFetching && hasMore) {
            const nextPage = currentPage + 1;
            debouncedDispatch(nextPage);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, isFetching, hasMore]);

    const topLoaderRef = useInfiniteScroll(loadMoreTickets, 'top');
    const bottomLoaderRef = useInfiniteScroll(loadMoreTickets, 'bottom');

    return (
        <>
            {sortedTickets?.length > 0 ? (
                <div>
                    <div>
                        <CustomScroll
                            ref={scrollContainerRef}
                            selector='tickets--list'
                            parentScrollId='allTickets'
                            activeElement={activeTicket?.ticketId}
                            axis='y'
                            className='tickets__list--container'>
                            {loadMoreDirection === 'top' && (
                                <div
                                    className={`loader-container loader-container--top ${isFetching ? 'is-loading' : ''}`}
                                    ref={topLoaderRef}>
                                    {isFetching && <LoadingShell />}
                                </div>
                            )}
                            {sortedTickets?.map((item, index) => (
                                <div
                                    key={item.ticketId}
                                >
                                    <Ticket
                                        data={item}
                                        handleTicketSelect={() => handleTicketSelect(item)}
                                        isActive={activeTicket?.ticketId === item?.ticketId}
                                        agents={agents}
                                        authUserId={userId}
                                    />
                                </div>
                            ))}


                            {loadMoreDirection === 'bottom' && (
                                <div
                                    className={`loader-container loader-container--bottom ${isFetching ? 'is-loading' : ''}`}
                                    ref={bottomLoaderRef}>
                                    {isFetching && <LoadingShell />}
                                </div>
                            )}

                        </CustomScroll>

                        {!hasMore && sortedTickets.length > 0 && (
                            <div className="empty__chat">
                                <p>{`You have reached the\n end of this list`}</p>
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                <EmptyInfo
                    customIcon={imageLinks?.icons?.emptyBuzz}
                    otherClassNames={"with-margin"}
                    message={`No Tickets\n assigned yet`}
                />
            )}
        </>
    );
};

export default CustomerTickets;
