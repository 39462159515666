import { ticketPhases } from "components/dashboard/AgentInbox/enum";
import store from "store/store";
import { setActiveTicket } from "store/tickets/actions";
import * as types from "./types";

const { ISSUE_DISCOVERY } = ticketPhases;

export const updateTicketsFilters = (data) => (dispatch) => {
    const {
        ticketsfilterRequest: { ticketPhase, totalRecords, pageSize },
    } = store.getState().ticketsFilters;

    const { filterName, filterValue, filterOption } = data;

    console.log("filterName, filterValue", filterName, filterValue);
    // Validate page number
    if (filterName === 'currentPage') {
        const maxPage = Math.ceil(totalRecords / pageSize);
        if (filterValue > maxPage || filterValue < 1) {
            return;
        }
    }
    if (filterName !== "currentPage") {
        dispatch({
            type: types.RESET_TICKETS_PAGINATION_FILTERS,
        });
    }

    if (ticketPhase === filterValue) {
        dispatch({
            type: types.UPDATE_TICKETS_FILTERS,
            payload: {
                filterName: "classification",
                filterValue: undefined,
                filterOption,
            },
        });
    }

    if (filterName === "ticketPhase") {
        dispatch({
            type: types.UPDATE_TICKETS_FILTERS,
            payload: {
                filterName: "followUpActive",
                filterValue: undefined,
                filterOption,
            },
        });
        dispatch({
            type: types.UPDATE_TICKETS_FILTERS,
            payload: {
                filterName: "isMentioned",
                filterValue: undefined,
                filterOption,
            },
        });
    }
    if (filterName === "ticketPhase" && filterValue !== ISSUE_DISCOVERY) {
        dispatch({
            type: types.UPDATE_TICKETS_FILTERS,
            payload: {
                filterName: "messageTagId",
                filterValue: undefined,
                filterOption,
            },
        });

        dispatch({
            type: types.UPDATE_TICKETS_FILTERS,
            payload: {
                filterName: "ticketInfoValue",
                filterValue: undefined,
                filterOption,
            },
        });

    } else if (
        filterName === "ticketPhase" &&
        filterValue === ISSUE_DISCOVERY
    ) {
        dispatch({
            type: types.UPDATE_TICKETS_FILTERS,
            payload: {
                filterName: "ticketInfoValue",
                filterValue: undefined,
                filterOption,
            },
        });

        // dispatch({
        //     type: types.UPDATE_TICKETS_FILTERS,
        //     payload: {
        //         filterName: "ticketSource",
        //         filterValue: undefined,
        //         filterOption,
        //     },
        // });
    }

    if (filterName === "followUpActive" || filterName === "isMentioned") {
        dispatch({
            type: types.UPDATE_TICKETS_FILTERS,
            payload: { filterName: "messageTagId", filterValue: undefined },
        });

        dispatch({
            type: types.UPDATE_TICKETS_FILTERS,
            payload: {
                filterName: "ticketInfoValue",
                filterValue: undefined,
                filterOption,
            },
        });

        dispatch({
            type: types.UPDATE_TICKETS_FILTERS,
            payload: {
                filterName: "classification",
                filterValue: undefined,
                filterOption,
            },
        });

        dispatch({
            type: types.UPDATE_TICKETS_FILTERS,
            payload: {
                filterName: "ticketPhase",
                filterValue: undefined,
                filterOption,
            },
        });
    }

    if (filterName === "isMentioned") {
        dispatch({
            type: types.UPDATE_TICKETS_FILTERS,
            payload: {
                filterName: "followUpActive",
                filterValue: undefined,
                filterOption,
            },
        });
    }

    if (filterName === "followUpActive") {
        dispatch({
            type: types.UPDATE_TICKETS_FILTERS,
            payload: {
                filterName: "isMentioned",
                filterValue: undefined,
                filterOption,
            },
        });
    }

    dispatch({ type: types.UPDATE_TICKETS_FILTERS, payload: data });
    dispatch(setActiveTicket(null));
};

export const saveTicketsInfoFilters = (data) => (dispatch) =>
    dispatch({ type: types.SAVE_AUTH_TICKET__INFO, payload: data });
