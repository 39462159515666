import { useCallback, useRef } from "react";

const useInfiniteScroll = (callback, direction = 'bottom') => {
    const observer = useRef();
    
    const lastElementRef = useCallback(node => {
      if (observer.current) observer.current.disconnect();
      
      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting) {
          callback();
        }
      }, {
        root: null,
        rootMargin: direction === 'top' 
          ? '-20px 0px 0px 0px'  // Only trigger when element is 20px from top
          : '0px 0px -20px 0px', // Only trigger when element is 20px from bottom
        threshold: 0.1, // Only needs to be 10% visible
       });
      
      if (node) observer.current.observe(node);
    }, [callback, direction]);
  
    return lastElementRef;
  };
  
export default useInfiniteScroll;