import React, { useState, useEffect } from "react";
import { apiRoute } from "services";
import API from "services/lib/api";
import {
    AUTOMATED,
    NO_TICKET_INFO,
    ticketInfoTypes,
    otherCategories,
    AGENT_GUIDE,
    ticketPhases,
    ASSIGNED,
} from "components/dashboard/AgentInbox/enum";
import TicketInfoCapsule from "components/dashboard/KnowledgeBase/common/Capsule/TicketInfoCapsule/TicketInfoCapsule";
import { useSelector } from "react-redux";
import { ticketsFilterBuilder } from "../../constants";
import TicketFilterSelector from "../../TicketFilterSelector/TicketFilterSelector";
import { UPDATE_WORKSPACE_INBOX_COUNT } from "services/socket/events";
import { truncateString } from "utils/helper";
import { useSocket } from "services/socket/hook";
import "./TicketsInfoElements.scss";

const { FORM, CAPSULE } = ticketInfoTypes;
const { MENTIONS, FOLLOW_UP } = otherCategories;

const TicketsInfoElements = ({
    authTicketInfo,
    filters,
    selectedTicketInfo,
    handleTicketInfoSelect,
    refreshData,
}) => {
    const { ticketPhase, followUpActive, isMentioned } = filters || {};
    const socket = useSocket();

    const [fetchingCount, sayFetchingCount] = useState(false);
    const [ticketsInfosCount, setTicketsInfoCount] = useState([]);
    const [isOthersSelected, selectOthers] = useState(false);
    const { capsuleClasses } = useSelector((state) => state.capsule);

    const filterBlockSelector = followUpActive
        ? FOLLOW_UP
        : isMentioned
            ? MENTIONS
            : ticketPhase;
    const filterBlock = ticketsFilterBuilder[filterBlockSelector];

    const { ticketsfilterRequest } = useSelector(
        (state) => state.ticketsFilters
    );

    const { agentId, search, ticketSource, all } = ticketsfilterRequest || {};

    const getTicketsCount = async () => {
        try {
            sayFetchingCount(true);
            const res = await API.get(apiRoute?.getTicketsInfoCounts, {
                params: {
                    ...filters,
                    agentId,
                    search,
                    ticketSource,
                    all
                },
            });

            if (res.status === 200) {
                const { data } = res?.data;
                setTicketsInfoCount(data);
                sayFetchingCount(false);
            }
        } catch (err) {
            sayFetchingCount(false);
        }
    };

    useEffect(() => {
        socket?.on(UPDATE_WORKSPACE_INBOX_COUNT, getTicketsCount);
        return () => {
            socket?.off(UPDATE_WORKSPACE_INBOX_COUNT);
        };
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        getTicketsCount();
        //eslint-disable-next-line
    }, [ticketPhase, refreshData, agentId, ticketSource, search])

    const otherTicketCapsulesInfo = ticketPhase === ticketPhases.ISSUE_DISCOVERY ? [] : ticketsInfosCount?.ticketInfo?.filter?.(
        (elem) => {
            return !authTicketInfo.some((ele) => {
                return (
                    (ele.ticketInfoType === elem.ticketInfoType &&
                        ele.ticketInfoValue === elem.ticketInfoValue) ||
                    elem?.ticketInfoType === AGENT_GUIDE
                );
            });
        }
    );

    const guideTicketInfos = ticketsInfosCount?.ticketInfo?.filter?.(
        ({ ticketInfoType }) => ticketInfoType === AGENT_GUIDE
    );

    const conversationTicketInfos = ticketsInfosCount?.ticketInfo?.filter?.(
        ({ ticketInfoType }) => ticketInfoType === ticketInfoTypes.CONVERSATION
    );



    return (
        <div className='tickets__infos'>
            <TicketFilterSelector
                label={`Automated`}
                fetchingCount={fetchingCount}
                isActive={selectedTicketInfo === AUTOMATED}
                onClick={() => handleTicketInfoSelect(AUTOMATED)}
                countValue={ticketsInfosCount?.automated}
                otherClassNames={"ticket--info"}
                activeStyles={filterBlock?.activeStyles}
            // hasNew={}
            />
            <TicketFilterSelector
                label={`No Info Included`}
                fetchingCount={fetchingCount}
                isActive={selectedTicketInfo === NO_TICKET_INFO}
                onClick={() => handleTicketInfoSelect(NO_TICKET_INFO)}
                countValue={ticketsInfosCount?.noTicketInfo}
                otherClassNames={"ticket--info"}
                activeStyles={filterBlock?.activeStyles}
            // hasNew={}
            />

            <TicketFilterSelector
                label={`All Tickets`}
                fetchingCount={fetchingCount}
                isActive={selectedTicketInfo === ASSIGNED}
                onClick={() => handleTicketInfoSelect(ASSIGNED)}
                countValue={ticketsInfosCount?.assignedCount}
                otherClassNames={"ticket--info"}
                activeStyles={filterBlock?.activeStyles}
            // hasNew={}
            />

            {isOthersSelected ? (
                <>
                    {otherTicketCapsulesInfo?.map?.((ticketInfo, index) => {
                        const {
                            ticketInfoCapsuleClassId,
                            ticketInfoValue,
                            count,
                        } = ticketInfo;
                        const isActive = selectedTicketInfo === ticketInfoValue;

                        return (
                            <TicketInfoCapsule
                                key={index}
                                capsule={capsuleClasses?.find(
                                    (x) =>
                                        x.capsuleClassId ===
                                        ticketInfoCapsuleClassId
                                )}
                                capsuleEntryId={ticketInfoValue}
                                isActive={isActive}
                                onClick={() =>
                                    handleTicketInfoSelect(ticketInfoValue)
                                }
                                otherClassNames={"ticket--info"}
                                countValue={count}
                                fetchingCount={fetchingCount}
                                activeStyles={filterBlock?.activeStyles}
                            // hasNew={}
                            />
                        );
                    })}
                </>
            ) : (
                <>
                    {otherTicketCapsulesInfo?.length > 0 && (
                        <TicketFilterSelector
                            label={`Others`}
                            fetchingCount={false}
                            isActive={false}
                            onClick={() => selectOthers(true)}
                            countValue={otherTicketCapsulesInfo?.length}
                            otherClassNames={"ticket--info"}
                            activeStyles={filterBlock?.activeStyles}
                        // hasNew={}
                        />
                    )}
                </>
            )}

            {conversationTicketInfos?.map?.((ticketInfo, index) => {
                const {
                    // ticketInfoCapsuleClassId,
                    ticketInfoValue,
                    count,
                    issueName,
                } = ticketInfo;
                const isActive = selectedTicketInfo === ticketInfoValue;

                return (
                    <TicketFilterSelector
                        key={index}
                        // fetchingCount={fetchingCount}
                        isActive={isActive}
                        onClick={() =>
                            handleTicketInfoSelect(ticketInfoValue, AGENT_GUIDE)
                        }
                        label={truncateString(issueName, 20)}
                        countValue={count}
                        otherClassNames={"ticket--info"}
                        activeStyles={filterBlock?.activeStyles}
                        ticketInfo={ticketInfo}
                    // hasNew={}
                    />
                );
            })}

            {guideTicketInfos?.map?.((ticketInfo, index) => {
                const {
                    // ticketInfoCapsuleClassId,
                    ticketInfoValue,
                    count,
                    agentGuideName,
                } = ticketInfo;
                const isActive = selectedTicketInfo === ticketInfoValue;

                return (
                    <TicketFilterSelector
                        key={index}
                        // fetchingCount={fetchingCount}
                        isActive={isActive}
                        onClick={() =>
                            handleTicketInfoSelect(ticketInfoValue, AGENT_GUIDE)
                        }
                        label={truncateString(agentGuideName, 20)}
                        countValue={count}
                        otherClassNames={"ticket--info"}
                        activeStyles={filterBlock?.activeStyles}
                        ticketInfo={ticketInfo}
                    // hasNew={}
                    />
                );
            })}

            {authTicketInfo?.map((ticketInfo, index) => {
                const {
                    ticketInfoType,
                    ticketInfoCapsuleClassId,
                    ticketInfoValue,
                    formName
                } = ticketInfo;
                const countInfo = ticketsInfosCount?.ticketInfo?.find(
                    (x) => x.ticketInfoValue === ticketInfoValue
                );
                const count = countInfo ? countInfo?.count : "0";
                const isActive = selectedTicketInfo === ticketInfoValue;
                // con
                return ticketInfoType === CAPSULE ? (
                    <>
                        {parseInt(count) !== 0 && (
                            <TicketInfoCapsule
                                key={index}
                                capsule={capsuleClasses?.find(
                                    (x) =>
                                        x.capsuleClassId ===
                                        ticketInfoCapsuleClassId
                                )}
                                capsuleEntryId={ticketInfoValue}
                                isActive={isActive}
                                onClick={() =>
                                    handleTicketInfoSelect(ticketInfoValue)
                                }
                                otherClassNames={"ticket--info"}
                                countValue={count}
                                fetchingCount={fetchingCount}
                                activeStyles={filterBlock?.activeStyles}
                            // hasNew={}
                            />
                        )}
                    </>
                ) : ticketInfoType === FORM ? (
                    <>
                        {parseInt(count) !== 0 && (
                            <TicketFilterSelector
                                key={index}
                                fetchingCount={fetchingCount}
                                isActive={isActive}
                                onClick={() =>
                                    handleTicketInfoSelect(ticketInfoValue)
                                }
                                label={formName}
                                countValue={count}
                                otherClassNames={"ticket--info"}
                                activeStyles={filterBlock?.activeStyles}
                            // hasNew={}
                            />
                        )}
                    </>
                ) : ticketInfoType === AGENT_GUIDE ? (
                    <>
                        {parseInt(count) !== 0 && (
                            <TicketFilterSelector
                                key={index}
                                fetchingCount={fetchingCount}
                                isActive={isActive}
                                onClick={() =>
                                    handleTicketInfoSelect(ticketInfoValue)
                                }
                                label={formName}
                                countValue={count}
                                otherClassNames={"ticket--info"}
                                activeStyles={filterBlock?.activeStyles}
                            // hasNew={}
                            />
                        )}
                    </>
                ) : (
                    ""
                );
            })}
        </div>
    );
};

export default TicketsInfoElements;
